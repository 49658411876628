import { createApp } from 'vue';
import App from './App.vue';
import { vLongPress } from './directives/v-long-press';

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-green/theme.css';
import Button from "primevue/button";
import Dialog from 'primevue/dialog';
import './registerServiceWorker';

const app = createApp(App);
app.use(PrimeVue);
app.component('PrimeButton', Button);
app.component('PrimeDialog', Dialog);

// Register the v-long-press directive
app.directive('long-press', {
  mounted: vLongPress
});

app.mount('#app');