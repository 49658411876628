<template>
    <main>
        <div class="header">
            <h1>Daily Tasks</h1>
        </div>
        <ul class="task-list">
            <li v-for="(task, index) in tasks" :key="index" class="task-container">
                <div
                    @touchstart="touchStart"
                    @touchend="(event) => touchEnd(event, index)"
                    v-long-press="() => onLongPress(task)"
                    :class="['task-item', { 'completed': task.lastCompletedDate === getCurrentDate() }]">{{ task.name }}</div>
                <button @click="toggleComplete(index)" :disabled="task.lastCompletedDate === getCurrentDate()" class="complete-button">{{ task.streak }}</button>
                <button class="edit-btn" @click="edit(task, index)" v-if="task.edit">Edit</button>
                <button class="delete-btn" @click="deleteTask(task, index)" v-if="task.edit">Delete</button>
            </li>
        </ul>
        <button @click="showPopup = true" class="add-button">Add Task</button>
        
        <div v-if="showPopup" class="popup-overlay">
            <div class="popup">
                <h2>Add New Task</h2>
                <input v-model="newTask" placeholder="New Task" class="task-input" />
                <div class="btn-row">
                    <button @click="addTask" class="popup-add-button">Add</button>
                    <button @click="showPopup = false" class="popup-close-button">Close</button>
                </div>
            </div>
        </div>
        <div v-if="showEditPopup" class="popup-overlay">
            <div class="popup">
                <h2>Edit Task</h2>
                <input v-model="newTask" placeholder="New Task" class="task-input" />
                <div class="btn-row">
                    <button @click="saveEdit" class="popup-add-button">Save</button>
                    <button @click="showEditPopup = false" class="popup-close-button">Close</button>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { vLongPress } from '../directives/v-long-press';

const tasks = ref(JSON.parse(localStorage.getItem('tasks')) ?? []);
const selectedIndex = ref();
const newTask = ref('');

const showPopup = ref(false);
const showEditPopup = ref(false);

const toggleComplete = (index) => {
  const task = tasks.value[index];
  const today = new Date().toISOString().split('T')[0];
  
  if (task.lastCompletedDate !== today) {
    task.streak++;
    task.lastCompletedDate = today;
    localStorage.setItem('tasks', JSON.stringify(tasks.value));
  }
};

const resetDailyCompletion = () => {
  const today = new Date().toISOString().split('T')[0];
  tasks.value.forEach(task => {
    if (task.lastCompletedDate !== today) {
      if (task.lastCompletedDate !== getPreviousDay(today)) {
        task.streak = 0;
      }
    }
  });
  localStorage.setItem('tasks', JSON.stringify(tasks.value));
};

function getPreviousDay(date) {
  const previous = new Date(date);
  previous.setDate(previous.getDate() - 1);
  return previous.toISOString().split('T')[0];
}

function getCurrentDate() {
  return new Date().toISOString().split('T')[0];
}

const addTask = () => {
  if (newTask.value.trim() !== '') {
    tasks.value.push({ 
      name: newTask.value, 
      streak: 0, 
      edit: false, 
      lastCompletedDate: null
    });
    newTask.value = '';
    showPopup.value = false;
  }
};

const closeEditMode = (event) => {
  tasks.value.forEach(task => {
    if (task.edit && !event.target.closest('.task-container')) {
      task.edit = false;
    }
  });
};

onMounted(() => {
  document.addEventListener('click', closeEditMode);
  resetDailyCompletion();
});

onUnmounted(() => {
  document.removeEventListener('click', closeEditMode);
});

function onLongPress(task) {
  console.log('Long press');
  tasks.value.forEach(t => t.edit = false); // Close other edit modes
  task.edit = true;
}

function edit(task, index) {
    console.log(task, index)
    showEditPopup.value = true;
    newTask.value = task.name;
    selectedIndex.value = index;
}

function saveEdit() {
    console.log('tasks', tasks.value);
    tasks[selectedIndex.value].name = newTask.value;
    newTask.value = '';
    showEditPopup.value = false;
}

function deleteTask(task, index) {
    tasks.value.splice(index, 1);
    console.log(task,index);
}

let touchStartX = 0;

const touchStart = (event) => {
  touchStartX = event.touches[0].clientX;
};

const touchEnd = (event, index) => {
  const touchEndX = event.changedTouches[0].clientX;
  const diffX = touchStartX - touchEndX;

  if (diffX > 50) { // Left swipe
    undoComplete(index);
  }
};

const undoComplete = (index) => {
  const task = tasks.value[index];
  const today = new Date().toISOString().split('T')[0];
  
  if (task.lastCompletedDate === today) {
    task.streak = Math.max(0, task.streak - 1);
    task.lastCompletedDate = null;
    localStorage.setItem('tasks', JSON.stringify(tasks.value));
  }
};

watch(tasks, (newTasks) => {
  localStorage.setItem('tasks', JSON.stringify(newTasks));
}, { deep: true });
</script>

<style>
html {
    background-color: #d8e0e2;
    width: 100vw;

}
body{
    margin: 0;
    display: flex;
}
main {
    width: inherit;
    display: flex;
    flex-direction: column;
}
.header {
    background-color: #007bb5;
}
.task-list {
    list-style-type: none;
    padding: 5px;
    width: 100%;
}

.task-container {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
}

.task-item {
    display: flex;
    flex: 5;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #ddd;
    width: inherit;
    height: 50px;
    padding: 5px 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    user-select: none; /* Prevent text selection during swipe */
}

.task-item.completed {
  text-decoration: line-through;
  color: #888;
}

.complete-button {
    flex: 1;
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    height: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.complete-button:hover {
    background-color: #45a049;
}

.complete-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-button {
    background-color: #008cba;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: 50%;
    align-self: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 100px;
}

.add-button:hover {
    background-color: #007bb5;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    background: white;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 80%;
}

.popup-add-button, .popup-close-button {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.popup-add-button {
    background-color: #4caf50;
    color: white;
}

.popup-close-button {
    background-color: #f44336;
    color: white;
}

.edit-btn {
    flex: 1;
    background-color: #d1c62b;
    color: white;
    border: none;
    padding: 5px 1px;
    cursor: pointer;
    height: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.delete-btn {
    flex: 1;
    background-color: #d12b2b;
    color: white;
    border: none;
    padding: 5px 1px;
    cursor: pointer;
    height: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>