<template>
    <HomePage />
</template>

<script>
import HomePage from './components/HomePage.vue'
//import TrackerView from './components/TrackerView.vue'
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    min-height: 100vh;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: rgb(190, 113, 113);
}
.component {
  width: 100%;
  height: 100%;
}
</style>
