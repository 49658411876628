export const vLongPress = {
  mounted(el, binding) {
    if (typeof binding.value !== 'function') {
      console.warn('v-long-press expects a function as value');
      return;
    }

    let pressTimer = null;
    const longPressDuration = 500; // milliseconds

    const start = (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return;
      }

      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          binding.value();
        }, longPressDuration);
      }
    };

    const cancel = () => {
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };

    el.addEventListener('mousedown', start);
    el.addEventListener('touchstart', start);
    el.addEventListener('click', cancel);
    el.addEventListener('mouseout', cancel);
    el.addEventListener('touchend', cancel);
    el.addEventListener('touchcancel', cancel);

    el._longPressEvents = { start, cancel };
  },

  unmounted(el) {
    const { start, cancel } = el._longPressEvents;
    el.removeEventListener('mousedown', start);
    el.removeEventListener('touchstart', start);
    el.removeEventListener('click', cancel);
    el.removeEventListener('mouseout', cancel);
    el.removeEventListener('touchend', cancel);
    el.removeEventListener('touchcancel', cancel);

    delete el._longPressEvents;
  }
};