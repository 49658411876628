<template>
    <div class="view-port">
        <div class="main-content">
            <div class="home-page" v-if="activeTab == 'home'">
                <div style="display: flex; justify-content: center;">
                    <div class="name-card">
                        <h1>JB</h1>
                    </div>
                </div>
                <div class="stat-container">

                    <div class="statistic">
                        <h2>Total Goals:</h2> <h2>5</h2>
                    </div>
                    <div class="statistic">
                        <h2>Longest Streak:</h2> <h2>8 days</h2>
                    </div>
                    <div class="statistic">
                        <h2>Calendar Events:</h2> <h2>6</h2>
                    </div>
                </div>
                
            </div>
            <div class="goals-page" v-if="activeTab == 'goals'">
                <TrackerView />
            </div>
            <div class="calendar-page" v-if="activeTab == 'calendar'">

            </div>
        </div>
        <div class="tab-container">
            <div class="tab-btn" @click="setActiveTab('home')">
                <i class="fas fa-home"></i>
            </div>
            <div class="tab-btn" @click="setActiveTab('goals')">
                <i class="fas fa-check"></i>
            </div>
            <div class="tab-btn" @click="setActiveTab('calendar')">
                <i class="fas fa-calendar"></i>
            </div>
            <div class="glider" :style="gliderStyle"></div>
        </div>
    </div>

</template>

<script setup>
import { ref, computed } from 'vue';
import TrackerView from './TrackerView.vue';

const activeTab = ref('home');

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

const gliderStyle = computed(() => {
  const positions = {
    'home': '0',
    'goals': '1',
    'calendar': '2'
  };
  return {
    transform: `translateX(${positions[activeTab.value] * 100}%)`
  };
});
</script>

<style scoped>
.main-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    flex: 1;
}

.name-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(197, 88, 88);
    padding: 5px 20px 5px 20px;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-top: 20px;
    z-index: 2;
    box-shadow: 0px 2px 8px grey;

}

.tab-container {
  display: flex;
  position: relative;
  background-color: #f0f0f0;
  padding: 4px;
  border-radius: 20px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.tab-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.count {
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 5px;
}

.glider {
  position: absolute;
  height: calc(100% - 8px);
  width: calc(33.6% - 4px);
  background-color: white;
  border-radius: 16px;
  transition: transform 0.25s ease-out;
  top: 4px;
  left: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Ensure tab content is above the glider */
.tab-btn p, .tab-btn .count {
  position: relative;
  z-index: 2;
}

.view-port {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;

}

.tab-btn i {
  font-size: 18px;
}

.goals-page {
    background-color: #e3f0ab;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.calendar-page {
    background-color: #abc6f0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.home-page {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.stat-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
    box-shadow: 1px 4px 20px 0px grey;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: 20px;
    height: 100%;
    background-color: #cb6363;
}
.statistic {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    gap: 10px;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 1px 3px 20px 0px #00000024;

}
.statistic h2 {
    margin: 0px;
}
</style>